<template>
  <Modal v-model="modalModifyServiceRule" :title="modalTitle" :mask-closable="false" width="800">
    <Row class="m-b-10">
      <i-col span="12">
        <p class="title m-b-5">运维公司</p>
        <Select v-model="serviceRuleBean.companyId" size="small" style="width:250px" placeholder="请选择运维公司">
          <Option v-for="item in maintenanceCompanyList" :key="item.id" :value="item.id">{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="12">
        <p class="title m-b-5">设置为任务默认供应商</p>
        <i-switch>
          <Icon type="md-checkmark" slot="open"></Icon>
          <Icon type="md-close" slot="close"></Icon>
        </i-switch>
      </i-col>
    </Row>
    <Row class="m-b-10">
      <i-col span="24">
        <p class="title m-b-5">运维资产</p>
        <div style="border-bottom: 1px solid #e9e9e9;padding-bottom:6px;margin-bottom:6px;">
          <Checkbox
              :indeterminate="indeterminate"
              :value="checkAll"
              @click.prevent.native="handleCheckAll">全选</Checkbox>
        </div>
        <CheckboxGroup v-model="checkAllGroup" @on-change="checkAllGroupChange">
            <Checkbox v-for="asset in assetArray" :key="asset.id" :label="asset.id">{{asset.name}}</Checkbox>
        </CheckboxGroup>
      </i-col>
    </Row>

    <Row class="m-b-20">
      <i-col span="24">
        <p class="title m-b-5">运维区域</p>
        <div style="border-bottom: 1px solid #e9e9e9;padding-bottom:6px;margin-bottom:6px;">
          <Checkbox
              :indeterminate="indeterminate"
              :value="checkAll"
              @click.prevent.native="handleCheckAll">全选</Checkbox>
        </div>
        <CheckboxGroup v-model="checkAllGroup" @on-change="checkAllGroupChange">
            <Checkbox v-for="(item,index) in positionArray" :key="index" :label="item.value">{{item.name}}</Checkbox>
        </CheckboxGroup>
      </i-col>
    </Row>

    <Tabs value="name1">
      <TabPane label="人工费" name="name1">
        <Row class="m-b-5 m-t-5">
          <i-col span="12" class="title">明细名称</i-col>
          <i-col span="6" class="title">金额</i-col>
          <i-col span="6" class="title">单位</i-col>
        </Row>
        <Row v-for="(item,i) in laborCostsArray" :key="i" class="m-b-5">
          <i-col span="12">
            <Input v-model="item.freeName" size="small" style="width: 300px;" placeholder="请填写明细名称" />
          </i-col>
          <i-col span="6">
            <InputNumber
              :max="1000000"
              size="small"
               style="width:100px"
              v-model="item.price"
              :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
              :parser="value => value.replace(/\￥\s?|(,*)/g, '')"></InputNumber>
          </i-col>
          <i-col span="6">
            <Select size="small" v-model="item.unit" style="width:100px" transfer>
              <Option v-for="unit in unitList" :key="unit.value" :value="unit.value">{{unit.name}}</Option>
            </Select>
          </i-col>
        </Row>
        <p class="m-t-5">
          <a @click="hanldeAddLaborCosts"><Icon type="md-add" size="16"/>添加明细</a>
        </p>
      </TabPane>
      <TabPane label="材料费" name="name2">
        <Row class="m-b-5 m-t-5">
          <i-col span="8" class="title">明细名称</i-col>
          <i-col span="3" class="title">金额</i-col>
          <i-col span="3" class="title">单位</i-col>
          <i-col span="6" class="title">开始结束时间</i-col>
          <i-col span="3" class="title">质保有效期</i-col>
        </Row>
        <Row v-for="(item,i) in materialArray" :key="i" class="m-b-5">
          <i-col span="8">
            <Select v-model="item.materialId" size="small" style="width: 230px;" placeholder="请选择制作工艺材质" transfer>
              <Option v-for="material in materialList" :key="material.id" :value="material.id">{{material.name}}</Option>
            </Select>
          </i-col>
          <i-col span="3">
            <InputNumber
              :max="1000000"
              size="small"
               style="width:80px"
              v-model="item.price"
              :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
              :parser="value => value.replace(/\￥\s?|(,*)/g, '')"></InputNumber>
          </i-col>
          <i-col span="3">
            <Select size="small" v-model="item.unit" style="width:80px" transfer>
              <Option v-for="unit in unitList" :key="unit.value" :value="unit.value">{{unit.name}}</Option>
            </Select>
          </i-col>
          <i-col span="6">
            <TimePicker type="timerange" size="small" v-model="item.startEndTime" placeholder="设置开始结束时间" style="width: 180px" transfer></TimePicker>
          </i-col>
          <i-col span="3">
            <InputNumber :min="0" :max="10000" style="width:80px" size="small" v-model="item.period"></InputNumber>
            <span style="margin-left: 3px;">天</span>
          </i-col>
        </Row>
        <p class="m-t-5">
          <a @click="handleAddMaterial"><Icon type="md-add" size="16"/>添加明细</a>
        </p>
      </TabPane>
    </Tabs>

    <div slot="footer" >
      <Button class="btn-black m-r-5" style="width:60px" size="small" @click="modalModifyServiceRule = false">取消</Button>
      <Button class="m-l-8" type="success" size="small" @click="handleSubmitServiceRule">{{isAdd?'新增':'保存'}}服务规则项</Button>
    </div>
  </Modal>
</template>

<script>
import em from '@/utils/enum'
import { getCompanyList } from '@/api/os/company'
import { getServiceRuleList, addServiceRule } from '@/api/msp/servicerule'
import { getChargeStatus } from '@/api/msp/status'
import { getPublisherAssetList } from '@/api/rim/asset'
import { getMaterialList } from '@/api/msp/serviceitem'

export default {
  props: {
    companyId: [String, Number],
    publisherId: [String, Number]
  },
  data () {
    return {
      modalModifyServiceRule: false,
      modalTitle: '',
      isAdd: true, // 是否添加操作

      materialList: [], // 工艺材质清单

      costType: 0,
      editIndex: 0,
      positionArray: em.positionEnum,
      unitList: [], // 计费单位
      feeTypeArray: em.maintenanceFeeTypeEnum,
      maintenanceCompanyList: [], // 运维公司清单

      serviceRuleBean: {
        companyId: null,
        companyName: '',
        price: 0,
        unit: 1,
        group: null,
        groupName: '',
        period: 365,
        feeType: 1,
        feeTypeName: '',
        startTime: '00:00:00',
        endTime: '23:59:59',
        position: null,
        positionName: '',
        assetId: -1,
        assetName: '',
        stationId: -1
      },
      newServiceGroup: '',
      serviceRuleGroups: [],

      laborCostsArray: [], // 人工费清单
      materialArray: [], // 材料费清单

      assetArray: [],
      indeterminate: false,
      checkAll: false,
      checkAllGroup: [],

      stationArray: []
    }
  },
  created () {
    this.initBaseData()

    this.loadMaintenanceCompanyList()
    this.loadServiceRuleGroupList()
    this.loadServiceUnit()
  },
  methods: {
    initBaseData () {
      getMaterialList().then(res => {
        this.materialList = res
      })
    },
    loadMaintenanceCompanyList () {
      const postData2 = {
        publisherId: this.publisherId,
        enabled: true,
        type: 3

      }
      getCompanyList(postData2).then(res => {
        this.maintenanceCompanyList = res
      })
    },
    loadServiceRuleGroupList () {
      const postData = {
        publisherId: this.publisherId,
        companyId: this.companyId
      }

      getServiceRuleList(postData).then(res => {
        this.serviceRuleGroups = res
      })
    },
    loadServiceUnit () {
      getChargeStatus().then(res => {
        this.unitList = res
      })
    },
    async getAssetData () {
      const result = await getPublisherAssetList() || []
      this.assetArray = [{ id: -1, name: '全线路' }, ...result]
    },
    showModal (isAdd, costType, editBean, editIndex) {
      this.isAdd = isAdd
      this.modalModifyServiceRule = true

      this.modalTitle = isAdd ? '新增' : '编辑'
      this.modalTitle += '成本项（'
      this.modalTitle += costType
      this.modalTitle += '）'

      this.costType = costType
      this.editIndex = editIndex

      this.getAssetData()

      if (isAdd) {
        this.laborCostsArray = [{
          freeName: '',
          price: 0,
          unit: 0
        }]

        this.materialArray = [{
          materialId: 1,
          price: 0,
          unit: 0,
          startEndTime: [],
          period: 0
        }]
      } else {
        this.serviceRuleBean = editBean
      }
    },
    hanldeAddLaborCosts () {
      this.laborCostsArray.push({
        freeName: '',
        price: 0,
        unit: 0
      })
    },
    handleAddMaterial () {
      this.materialArray.push({
        materialId: 1,
        price: 0,
        unit: 0,
        startEndTime: [],
        period: 0
      })
    },

    handleSubmitServiceRule () {
      this.serviceRuleBean.companyName = this.maintenanceCompanyList.find(x => x.id === this.serviceRuleBean.companyId).name
      this.serviceRuleBean.feeTypeName = this.feeTypeArray.find(x => x.value === this.serviceRuleBean.feeType).name
      this.serviceRuleBean.unitName = this.unitList.find(x => x.value === this.serviceRuleBean.unit).name
      this.serviceRuleBean.positionName = this.positionArray.find(x => x.value === this.serviceRuleBean.position).name
      this.serviceRuleBean.assetName = this.assetArray.find(x => x.id === this.serviceRuleBean.assetId).name

      if (this.costType === 5 && this.serviceRuleBean.group && this.serviceRuleBean.group !== -1) {
        this.serviceRuleBean.groupName = this.serviceRuleGroups.find(x => x.id === this.serviceRuleBean.group).name
      }

      this.$emit('update-sevice-rule',
        {
          isAdd: this.isAdd,
          editIndex: this.editIndex,
          serviceRuleBean: this.serviceRuleBean
        }
      )

      this.modalModifyServiceRule = false
    },
    handleAddServiceGroup () {
      this.newServiceGroup = ''
      const self = this

      this.$Modal.confirm({
        render: (h) => {
          return h('Input', {
            props: {
              value: this.newServiceGroup,
              autofocus: true,
              placeholder: '请输入分组名称...'
            },
            on: {
              input: (val) => {
                this.newServiceGroup = val
              }
            }
          })
        },
        loading: true,
        onOk: () => {
          if (self.newServiceGroup.trim().length > 1) {
            const serviceRuleBean = {
              publisherId: self.publisherId,
              companyId: self.companyId,
              name: self.newServiceGroup
            }

            // 开始添加新的服务规则
            addServiceRule(serviceRuleBean).then(() => {
              self.loadServiceRuleList()
              self.$Notice.success({ desc: '规则分组添加成功' })
              self.$Modal.remove()
            })
          } else {
            self.$Notice.warning({ title: '请求异常', desc: '规则分组名称不能空！' })
            self.$Modal.remove()
          }
        }
      })

      document.getElementsByClassName('ivu-modal-confirm')[0].parentNode.parentNode.parentNode.style.cssText =
        'margin-top: 100px; width: 416px;'
    },
    handleCheckAll () {
      if (this.indeterminate) {
        this.checkAll = false
      } else {
        this.checkAll = !this.checkAll
      }
      this.indeterminate = false

      if (this.checkAll) {
        this.checkAllGroup = this.assetArray.map(x => x.id)
      } else {
        this.checkAllGroup = []
      }
    },
    checkAllGroupChange (data) {
      if (data.length === this.assetArray.length) {
        this.indeterminate = false
        this.checkAll = true
      } else if (data.length > 0) {
        this.indeterminate = true
        this.checkAll = false
      } else {
        this.indeterminate = false
        this.checkAll = false
      }
    }
  }
}
</script>
